import {
  IAppService,
  IStateConfig,
  IFxpAppContext,
  IServiceEndPoints,
  IRouteInfo,
  IPartnerBundle,
} from "@fxp/fxpservices";
import { appSettings } from "./environments/common/appsettingsGenerator";
import { oldUrlMappings } from "../src/app/services/oldURLMappings";
import OldRouteComponentProvider from "./app/components/Providers/OldRouteComponentProvider";
import { PartnerAppRegistrationService } from '@fxp/fxpservices';

export class PSDICentral_routes implements IAppService {
  getRoutes(fxpContext: IFxpAppContext): IRouteInfo {
    const assetsCatalogRoute: IStateConfig = {
      name: "catalogPage",
      url: "/psdicentra/assets/catalog",
generatedBundle: 'e237a0ab4f79-assetsComponentProvider-Bundle',
      componentFramework: "React",
      data: {
        headerName: "PSDI Central",
        breadcrumbText: "PSDI Central",
        pageTitle: "PSDI Central",
        partnerTelemetryName: "PSDICentra",
      },
      params: {
        business: "catalog",
      },
    };

    const powerSlideRoute: IStateConfig = {
      name: "powerSlidePage",
      url: "/psdicentra/powerslide",
generatedBundle: 'e237a0ab4f79-assetsComponentProvider-Bundle',
      componentFramework: "React",
      data: {
        headerName: "PSDI Central",
        breadcrumbText: "PSDI Central",
        pageTitle: "PSDI Central",
        partnerTelemetryName: "PSDICentra",
      },
      params: {
        business: "catalog",
      },
    };

    const statisticsRoute: IStateConfig = {
      name: "StatisticsPage",
      url: "/psdicentra/statistics",
generatedBundle: 'e237a0ab4f79-statisticsComponentProvider-Bundle',
      componentFramework: "React",
      data: {
        headerName: "PSDI Central",
        breadcrumbText: "PSDI Central",
        pageTitle: "PSDI Central",
        partnerTelemetryName: "PSDICentra",
      },
      params: {
        business: "catalog",
      },
    };

    const slidePageRoute: IStateConfig = {
      name: "SlidePage",
      url: "/psdicentra/powerslide/:id",
generatedBundle: 'e237a0ab4f79-assetsComponentProvider-Bundle',
      componentFramework: "React",
      data: {
        headerName: "PSDI Central",
        breadcrumbText: "PSDI Central",
        pageTitle: "PSDI Central",
        partnerTelemetryName: "PSDICentra",
      },
      params: {
        business: "catalog",
      },
    };

    const l1CatalogRoute: IStateConfig = {
      name: "catalogPage",
      url: "/gcecceselfserve",
generatedBundle: 'e237a0ab4f79-assetsComponentProvider-Bundle',
      componentFramework: "React",
      data: {
        headerName: "PSDI Central",
        breadcrumbText: "PSDI Central",
        pageTitle: "PSDI Central",
        partnerTelemetryName: "PSDICentra",
      },
      params: {
        business: "catalog",
      },
    };

    const reportRoute: IStateConfig = {
      name: "reportPage",
      url: "/psdicentra/assets/powerbi/:reportId",
generatedBundle: 'e237a0ab4f79-reportComponentProvider-Bundle',
      componentFramework: "React",
      data: {
        headerName: "PSDI Central",
        breadcrumbText: "PSDI Central",
        pageTitle: "PSDI Central",
        partnerTelemetryName: "PSDICentra",
      },
    };
    
    const rsaRoute: IStateConfig = {
      name: "RSAPage",
      url: "/fxp-ces-resource-skill-availability",
generatedBundle: 'e237a0ab4f79-RSAComponentProvider-Bundle',
      componentFramework: "React",
      data: {
        headerName: "PSDI Central",
        breadcrumbText: "PSDI Central",
        pageTitle: "PSDI Central",
        partnerTelemetryName: "PSDICentra",
      },
    };

    const idletimeRoute: IStateConfig = {
      name: "IdleTimePage",
      url: "/fxp-ces-idle-time",
generatedBundle: 'e237a0ab4f79-RSAComponentProvider-Bundle',
      componentFramework: "React",
      data: {
        headerName: "PSDI Central",
        breadcrumbText: "PSDI Central",
        pageTitle: "PSDI Central",
        partnerTelemetryName: "PSDICentra",
      },
    };

    const CsaInsightsRoute: IStateConfig = {
      name: "CsaInsightsPage",
      url: "/fxp-gce-csa-insights",
generatedBundle: 'e237a0ab4f79-RSAComponentProvider-Bundle',
      componentFramework: "React",
      data: {
        headerName: "PSDI Central",
        breadcrumbText: "PSDI Central",
        pageTitle: "PSDI Central",
        partnerTelemetryName: "PSDICentra",
      },
    };

    const GceLeadershipRoute: IStateConfig = {
      name: "GceLeadershipPage",
      url: "/fxp-gce-leadership-insights",
generatedBundle: 'e237a0ab4f79-RSAComponentProvider-Bundle',
      componentFramework: "React",
      data: {
        headerName: "PSDI Central",
        breadcrumbText: "PSDI Central",
        pageTitle: "PSDI Central",
        partnerTelemetryName: "PSDICentra",
      },
    };

    const CsaManagerInsightsRoute: IStateConfig = {
      name: "CsaManagerInsightsPage",
      url: "/fxp-gce-csa-manager-insights",
generatedBundle: 'e237a0ab4f79-RSAComponentProvider-Bundle',
      componentFramework: "React",
      data: {
        headerName: "PSDI Central",
        breadcrumbText: "PSDI Central",
        pageTitle: "PSDI Central",
        partnerTelemetryName: "PSDICentra",
      },
    };

    const IsdCalenderRoute: IStateConfig = {
      name: "IsdCalenderPage",
      url: "/fxp-isd-calender-insights",
generatedBundle: 'e237a0ab4f79-RSAComponentProvider-Bundle',
      componentFramework: "React",
      data: {
        headerName: "PSDI Central",
        breadcrumbText: "PSDI Central",
        pageTitle: "PSDI Central",
        partnerTelemetryName: "PSDICentra",
      },
    };

    const t2csRoute: IStateConfig = {
      name: "t2csPage",
      url: "/fxp-t2cs-insights",
generatedBundle: 'e237a0ab4f79-RSAComponentProvider-Bundle',
      componentFramework: "React",
      data: {
        headerName: "PSDI Central",
        breadcrumbText: "PSDI Central",
        pageTitle: "PSDI Central",
        partnerTelemetryName: "PSDICentra",
      },
    };

    const availabilityInsightsRoute: IStateConfig = {
      name: "availabilityInsightsPage",
      url: "/resource-availability-insights",
generatedBundle: 'e237a0ab4f79-RSAComponentProvider-Bundle',
      componentFramework: "React",
      data: {
        headerName: "PSDI Central",
        breadcrumbText: "PSDI Central",
        pageTitle: "PSDI Central",
        partnerTelemetryName: "PSDICentra",
      },
    };

    const selfServeRoute: IStateConfig = {
      name: "selfServePage",
      url: "/psdicentra/assets/selfserve/:assetId",
generatedBundle: 'e237a0ab4f79-selfServeComponentProvider-Bundle',
      componentFramework: "React",
      data: {
        headerName: "PSDI Central",
        breadcrumbText: "PSDI Central",
        pageTitle: "PSDI Central",
        partnerTelemetryName: "PSDICentra",
      },
    };
    const favoritesRoute: IStateConfig = {
      name: "favoritesPage",
      url: "/psdicentra/favorites",
generatedBundle: 'e237a0ab4f79-favoritesComponentProvider-Bundle',
      componentFramework: "React",
      data: {
        headerName: "PSDI Central",
        breadcrumbText: "PSDI Central",
        pageTitle: "PSDI Central",
        partnerTelemetryName: "PSDICentra",
      },
    };
    const comingSoonRoute: IStateConfig = {
      name: "comingSoonPage",
      url: "/psdicentra/unknown",
generatedBundle: 'e237a0ab4f79-comingSoonComponent-Bundle',
      componentFramework: "React",
      data: {
        headerName: "PSDI Central",
        breadcrumbText: "PSDI Central",
        pageTitle: "PSDI Central",
        partnerTelemetryName: "PSDICentra",
      },
    };

    const errorRoute: IStateConfig = {
      name: "errorPage",
      url: "/psdicentra/error",
generatedBundle: 'e237a0ab4f79-errorComponent-Bundle',
      componentFramework: "React",
      data: {
        headerName: "PSDI Central",
        breadcrumbText: "PSDI Central",
        pageTitle: "PSDI Central",
        partnerTelemetryName: "PSDICentra",
      },
    };

    const KPIRoute: IStateConfig = {
      name: "KPIPage",
      url: "/psdicentra/kpipage",
generatedBundle: 'e237a0ab4f79-assetsComponentProvider-Bundle',
      componentFramework: "React",
      data: {
        headerName: "PSDI Central",
        breadcrumbText: "PSDI Central",
        pageTitle: "PSDI Central",
        partnerTelemetryName: "PSDICentra",
      },
    };
    // const oldRoutes: IStateConfig[] = oldUrlMappings.map((data) => {
    //   const route: IStateConfig = {
    //     name: "oldRoutePage",
    //     url: data.oldUrl,
    //     component: OldRouteComponentProvider,
    //     componentFramework: "React",
    //     data: {
    //       headerName: "PSDI Central",
    //       breadcrumbText: "PSDI Central",
    //       pageTitle: "PSDI Central",
    //       partnerTelemetryName: "PSDICentra",
    //     },
    //     params:{
    //       assetName: data.reportName
    //     }
    //   };
    //   return route;
    // });

    const routeInfo: IRouteInfo = {
      applicationName: "PSDICentra",
      sharedBundles: [],
      routes: [
        assetsCatalogRoute,
        l1CatalogRoute,
        reportRoute,
        selfServeRoute,
        favoritesRoute,
        comingSoonRoute,
        errorRoute,
        rsaRoute,
        idletimeRoute,
        CsaInsightsRoute,
        GceLeadershipRoute,
        availabilityInsightsRoute,
        IsdCalenderRoute,
        CsaManagerInsightsRoute,
        t2csRoute,
        powerSlideRoute,
        statisticsRoute,
        slidePageRoute,
        KPIRoute,
        // [...oldRoutes]
      ],
    };
    return routeInfo;
  }

  getServiceEndPoints(): Array<IServiceEndPoints> {
    return appSettings().serviceEndPoints;
  }

  getBundles(): IPartnerBundle[] {
    const baseUrl = appSettings().cdnBaseUrl;
    const bundle: IPartnerBundle = {
      name: "PSDICentra-Bundle",
      files: [
        `${baseUrl}/vendor.bundle.js`,
        `${baseUrl}/styles.bundle.js`,
        `${baseUrl}/main.bundle.js`,
      ],
      sequentialLoading: true,
    };
    return [bundle];
  }
  public getGeneratedBundles() {
    var baseUrl = '';
    var currentScriptUrl = document.currentScript['src'];
    if (currentScriptUrl.indexOf('exclude.inline.bundle') > 0) {
      currentScriptUrl = Error().stack.match(/(https?:\/\/[^ ]*)/g);
      baseUrl = currentScriptUrl
        .filter((item) => item.indexOf('localhost:5000') === -1)[0]
        .toLowerCase();
      baseUrl = baseUrl.substring(0, baseUrl.lastIndexOf('/'));
    } else {
      baseUrl = currentScriptUrl.substring(0, currentScriptUrl.lastIndexOf('/'));
    }
    if (baseUrl.endsWith("/")){
        baseUrl = baseUrl.substring(0, baseUrl.lastIndexOf('/'));
    }
    return [
            {
                name: 'e237a0ab4f79-assetsComponentProvider-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/assetsComponentProvider.vendor.bundle.js',
                    baseUrl + '/assetsComponentProvider.bundle.js'
                ],
                sequentialLoading: true
            },
    {
                name: 'e237a0ab4f79-statisticsComponentProvider-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/statisticsComponentProvider.vendor.bundle.js',
                    baseUrl + '/statisticsComponentProvider.bundle.js'
                ],
                sequentialLoading: true
            },
    {
                name: 'e237a0ab4f79-reportComponentProvider-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/reportComponentProvider.vendor.bundle.js',
                    baseUrl + '/reportComponentProvider.bundle.js'
                ],
                sequentialLoading: true
            },
    {
                name: 'e237a0ab4f79-RSAComponentProvider-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/RSAComponentProvider.vendor.bundle.js',
                    baseUrl + '/RSAComponentProvider.bundle.js'
                ],
                sequentialLoading: true
            },
    {
                name: 'e237a0ab4f79-selfServeComponentProvider-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/selfServeComponentProvider.vendor.bundle.js',
                    baseUrl + '/selfServeComponentProvider.bundle.js'
                ],
                sequentialLoading: true
            },
    {
                name: 'e237a0ab4f79-favoritesComponentProvider-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/favoritesComponentProvider.vendor.bundle.js',
                    baseUrl + '/favoritesComponentProvider.bundle.js'
                ],
                sequentialLoading: true
            },
    {
                name: 'e237a0ab4f79-comingSoonComponent-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/comingSoonComponent.vendor.bundle.js',
                    baseUrl + '/comingSoonComponent.bundle.js'
                ],
                sequentialLoading: true
            },
    {
                name: 'e237a0ab4f79-errorComponent-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/errorComponent.vendor.bundle.js',
                    baseUrl + '/errorComponent.bundle.js'
                ],
                sequentialLoading: true
            },

    ]
}
}

PartnerAppRegistrationService.registerLazyLoadedApp(PSDICentral_routes, 'appName');
