import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const prod: IPartnerAppSettings = {
  cdnBaseUrl: 'https://psdicentra.z30.web.core.windows.net',
  serviceEndPoints: [
    {
      clientId: 'cc1b9d80-0cfd-42dc-aea1-c610b6393ab9',
      serviceEndPoint: 'https://psdicentraapi.azurewebsites.net',
    }
  ],
};
