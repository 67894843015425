import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const sit: IPartnerAppSettings = {
  cdnBaseUrl: 'https://psdicentra.z30.web.core.windows.net',
  serviceEndPoints: [
    // {
    //   serviceEndPoint: 'https://psdicentraapi-dev.azurewebsites.net',
    // },
    {
      clientId: '6f40053e-5319-40e5-a90b-6f714506d96d',
      serviceEndPoint: 'https://psdicentraapi-dev.azurewebsites.net',
    }
  ],
};
